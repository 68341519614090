import React, { useState } from 'react'
import styled from 'styled-components'
import useStorage from '../hooks/use-storage'
import useTimeout from '../hooks/use-timeout'
import EmailInput from './emailInput'

const MAX_TRIES = 3
const POPUP_DELAY_MS = 4000
const CLOSE_POPUP_SUCCESS_DELAY = 1000

const NewsletterWrapper = styled.div`
  font-weight: 300;
  word-spacing: 6px;
  .content {
    line-height: 120%;
    opacity: 0.85;
    background-color: var(--black);
    border: 1px solid var(--white);
    color: var(--white);
    text-transform: uppercase;
  }
  input::placeholder {
    word-spacing: 6px;
    font-weight: 300;
  }
`

const NewsletterPopup = () => {
  const [open, setOpen] = useState(false)
  const [newsletterCanceledCount, setNewsletterCanceledCount] = useStorage({
    key: 'newsletterCanceledCount',
    initialValue: 0,
    type: 'localStorage',
  })
  const [hasBeenShownInSession, setHasBeenShownInSession] = useStorage({
    key: 'newsletterPopupShown',
    initialValue: false,
    type: 'sessionStorage',
  })

  const openIfAllowed = () => {
    if (newsletterCanceledCount >= MAX_TRIES || hasBeenShownInSession) return
    setHasBeenShownInSession(true)
    setOpen(true)
  }

  const closePopup = ({ customCount }) => {
    setNewsletterCanceledCount(customCount || newsletterCanceledCount + 1)
    setOpen(false)
  }

  useTimeout(openIfAllowed, POPUP_DELAY_MS)

  if (!open) return null

  return (
    <NewsletterWrapper className="h-screen w-full z-50 absolute flex justify-center items-center mono">
      <div className="content w-11/12 h-3/4 md:w-7/12 md:h-3/5 p-10 text-xl flex justify-between flex-col">
        <div className="flex justify-between">
          <div>
            <p className=" ">Subscribe to Piaule Newsletter</p>
            <p className="w-5/6 md:w-1/2 mt-10">
              One free stay gifted to one lucky subscriber every month
            </p>
          </div>
          <div onClick={closePopup} className="flex cursor-pointer">
            <p className="hidden whitespace-nowrap md:block">No Thanks</p>
            <p className="pl-10">X</p>
          </div>
        </div>
        <div className="w-min">
          <EmailInput
            onSuccess={() =>
              setTimeout(
                () => closePopup({ customCount: MAX_TRIES + 1 }),
                CLOSE_POPUP_SUCCESS_DELAY
              )
            }
          />
        </div>
      </div>
    </NewsletterWrapper>
  )
}

export default NewsletterPopup
