import { format } from 'date-fns'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from '../assets/images/logo.inline.svg'
import useNavigation from '../hooks/use-navigation'

const HeaderStyles = styled.header`
  nav {
    border-color: var(--grey);
  }

  a {
    color: var(--grey);
  }

  .reserve-btn {
    a {
      color: var(--black);
      transition: color 0.4s ease, background-color 0.4s ease;

      &:hover {
        background-color: var(--black);
        color: var(--white);
      }

      @media screen and (max-width: 1023px) {
        background-color: var(--black);
        color: var(--white);
      }
    }
  }

  .booking-active {
    background-color: transparent;
    color: var(--black);
    border-top: 1px solid black;

    nav {
      border-color: var(--white);
    }

    a {
      color: var(--white);
    }

    .side-logo--booking svg {
      fill: var(--white);
    }
  }

  .booking-active .reserve-btn a {
    @media screen and (min-width: 1024px) {
      border-color: var(--white);
      color: var(--white);
      overflow: hidden;

      &:hover {
        background-color: var(--white);
        color: var(--black);
      }

      span {
        animation: marquee 10s linear infinite;
        display: inline-block;
        width: max-content;
      }

      @keyframes marquee {
        0% {
          transform: translate(90%);
        }
        100% {
          transform: translate(-100%);
        }
      }
    }
  }

  .nav__link {
    border-top: 1px solid transparent;
  }

  .active {
    color: var(--black);
    border-color: black;
  }

  .side-logo svg {
    max-width: 100%;
  }

  @media screen and (max-width: 1023px) {
    li a {
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 1024px) {
    .booking-active {
      color: var(--white);
      border-top: none;
    }

    .active {
      border-top: none;
    }

    .reserve-btn {
      margin: -0.25rem 0 0 0.75rem;

      a {
        width: 100px;
      }
    }

    .side-logo:not(.side-logo--booking) svg {
      width: 220px;
    }

    nav ul {
      margin-top: -0.5rem;
    }

    .booking-active nav ul {
      margin-top: calc(-0.5rem - 1px);
    }
  }
`

const Header = ({ isBooking }) => {
  const navigation = useNavigation()
  const [date, setDate] = useState(new Date())
  const [temp, setTemp] = useState('')
  const [cond, setCond] = useState('')
  const zip = 12414
  const apiKey = '57f23fe5225cf6f01b7d940b9222d286'

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    let timer = setInterval(() => setDate(new Date()), 1000)

    const getWeather = async () => {
      await fetch(
        `https://api.openweathermap.org/data/2.5/weather?zip=${zip},us&units=imperial&appid=${apiKey}`,
        { signal }
      )
        .then(res => res.json())
        .then(data => {
          setTemp(data.main.temp.toFixed(0))
          setCond(data.weather[0].description)
        })
        .catch(err => {
          throw new Error(err)
        })
    }

    getWeather()

    return function cleanup() {
      clearInterval(timer)
      controller.abort()
    }
  }, [])

  return (
    <HeaderStyles className="sticky top-0 z-20">
      <div className={`w-full relative`}>
        <div
          className={`lg:w-full lg:flex lg:flex-row lg:justify-between ${
            isBooking ? 'booking-active lg:items-start' : 'lg:items-center bg-white'
          } lg:p-4`}
        >
          <div className={`lg:block p-3 lg:p-0 lg:w-1/3`}>
            <Link className={`side-logo${isBooking ? ' side-logo--booking' : ''}`} to="/">
              <Logo />
            </Link>
          </div>

          <nav className="flex align-center justify-center border-t border-b lg:border-t-0 lg:border-b-0 relative left-0 w-auto">
            <ul className="flex flex-row justify-between lg:justify-center items-center overflow-scroll sans-reg w-full">
              {navigation.navItems.map(item => (
                <li key={item._key} className="flex flex-row items-center">
                  <Link
                    activeClassName={'active'}
                    className="nav__link text-2xl uppercase px-3"
                    to={`/${item.slug}`}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>

            <div className={`reserve-btn hidden lg:block text-right`}>
              <Link
                to={
                  isBooking
                    ? '#'
                    : 'https://direct-book.com/piaule-catskill/properties/piaulecatskilldirect'
                }
                className={`text-l uppercase py-0.5 px-2 border border-black inline-block text-center box-border ${
                  isBooking ? 'cursor-default' : 'cursor-pointer'
                }`}
              >
                <span suppressHydrationWarning>
                  {isBooking ? `${temp}˚F ${cond} ${format(date, 'HH:mm:ss')}` : 'Reserve'}
                </span>
              </Link>
            </div>
          </nav>

          <div className={`reserve-btn ${isBooking ? 'hidden' : 'lg:hidden w-full'}`}>
            <Link
              to="https://direct-book.com/piaule-catskill/properties/piaulecatskilldirect"
              className="block border-2 border-black p-2 text-center text-l uppercase"
            >
              Reserve
            </Link>
          </div>
        </div>
      </div>
    </HeaderStyles>
  )
}

export default Header
