import { createGlobalStyle } from 'styled-components';

import fontUrlWoff2 from '../assets/fonts/ABCFavoritMono-Light.woff2';
import fontUrlWoff from '../assets/fonts/ABCFavoritMono-Light.woff';

const Typography = createGlobalStyle`
  @import url("https://p.typekit.net/p.css?s=1&k=wpp7bfb&ht=tk&f=39440.39441&a=4328234&app=typekit&e=css");

  @font-face {
    font-family: 'FavoritMono';
    src: url(${fontUrlWoff2}),
        url(${fontUrlWoff});
  }

  @font-face {
    font-family:"linotype-sabon";
    src:url("https://use.typekit.net/af/c572ac/00000000000000003b9b2010/27/l?subset_id=2&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/c572ac/00000000000000003b9b2010/27/d?subset_id=2&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/c572ac/00000000000000003b9b2010/27/a?subset_id=2&fvd=i4&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-family:"linotype-sabon";
    src:url("https://use.typekit.net/af/eb13d2/00000000000000003b9b2011/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/eb13d2/00000000000000003b9b2011/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/eb13d2/00000000000000003b9b2011/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Agipo Regular';
  }
`;

export default Typography;
