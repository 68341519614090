import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Header from './header'
import Footer from './footer'
import GlobalStyles from '../styles/globalStyles'
import Typography from '../styles/typography'
import NewsletterPopup from './newsletterPopup'
import { useLocation } from '@reach/router'
// import useNavigation from '../hooks/use-navigation'
// import { navigate } from 'gatsby-link'

const LayoutStyles = styled.div`
  // For the Way experiences tab
  #kouto-app-root > div > div > div:not(:first-child) {
    padding: 20px;
  }
`

const Layout = ({ children, scroll, uri }) => {
  const location = useLocation()
  const mainEl = useRef(null)
  const isBooking =
    new RegExp('^/booking(/.*)?$').test(location.pathname) || location.pathname === '/'

  const handleWheel = e => {
    const { deltaY } = e
    const { current } = mainEl

    if (current.scrollWidth === window.innerWidth) return

    current.scrollLeft += deltaY * 1.5

    /* if (
      current.scrollWidth - current.scrollLeft === window.innerWidth
      && index !== -1
      && index !== navigation.navItems.length - 1
    ) {
      window.removeEventListener('wheel', handleWheel);

      const { slug } = navigation.navItems[index + 1];

      navigate(`/${slug}`);
    } */
  }

  useEffect(() => {
    window.addEventListener('wheel', handleWheel, { passive: false })

    return function cleanup() {
      window.removeEventListener('wheel', handleWheel)
    }
  })

  return (
    <>
      <GlobalStyles />
      <Typography />
      <NewsletterPopup />
      <LayoutStyles
        className={`h-auto lg:h-screen w-full flex flex-col justify-between lg:overflow-scroll${
          scroll ? '' : ' lg:overflow-hidden'
        }`}
      >
        <Header isBooking={isBooking} />
        <main ref={mainEl} className="h-full w-full lg:overflow-scroll">
          {children}
        </main>

        <Footer />
        <script src="https://widget.siteminder.com/ibe.min.js"></script>
      </LayoutStyles>
    </>
  )
}

export default Layout
