import { useMatch } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import useFooter from '../hooks/use-footer'
import EmailInput from './emailInput'

const FooterStyles = styled.footer`
  color: var(--black);
  li {
    border-bottom: 1px dashed var(--black);
  }
  form {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed var(--black);
  }
  input::placeholder {
    color: var(--black);
  }
  button {
    font-size: 1.125rem;
  }
  .is-booking {
    color: var(--white);
    position: fixed;
    bottom: 0;
    li {
      border-bottom: 1px dashed var(--white);
    }
    form {
      border-bottom: 1px dashed var(--white);
    }
    input::placeholder {
      color: var(--white);
    }
  }

  @media screen and (max-width: 1023px) {
    &:not(.is-booking) {
      padding-bottom: 110px;
    }
  }

  @media screen and (min-width: 1024px) {
    .is-booking {
      color: var(--white);
      li {
        border-bottom: 1px dashed var(--white);
      }
      form {
        border-bottom: 1px dashed var(--white);
      }
      input::placeholder {
        color: var(--white);
      }
    }
  }

  .info-block {
    background-color: var(--blue);
    color: var(--white);
    li {
      border-bottom: none;
      &:first-of-type {
        max-width: 130px;
        word-break: break-all;
      }
      &:nth-of-type(2) {
        text-align: right;
        padding-right: 2rem;
      }
      &:nth-of-type(3) {
        text-align: center;
      }
    }
    .address {
      max-width: 300px;
    }
  }

`;

const Footer = () => {
  const footer = useFooter()

  const match = useMatch('/booking')

  return (
    <FooterStyles className="sticky bottom-0 z-20">
      <div className={`relative bottom-auto w-full flex flex-row items-end justify-between p-0 lg:p-4 ${match ? "is-booking" : ""}`}>
        <div className="mono text-xs uppercase flex flex-col lg:flex-row items-end w-full lg:w-auto">

          {/* mobile blue footer block */}
          {/* <div className="info-block block lg:hidden w-full flex flex-col agipoReg text-xl py-8 px-2 md:px-32">
            <p className="address text-center">{footer.address}</p>
            <p className="phone self-end">{footer.phone}</p>
            <ul>
              {footer.links.map(item => (
                <li key={item._key}>
                  {item.external
                    ? <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>
                    : <Link to={`/${item.link}`}>{item.text}</Link>
                  }
                </li>
              ))}
            </ul>
          </div> */}
          {/* end mobile blue footer block */}

          {/* email input */}
          <div className="hidden lg:block">
            <EmailInput />
          </div>
          {/* end email input */}

          {/* footer links */}
          <ul className="w-full flex flex-row align-center justify-between p-4 lg:p-0">
            {footer.footerItems.map(item => (
              <li key={item._key} className="md:ml-5">
                {item.external
                  ? <a href={item.link} target="_blank" rel="noopener noreferrer" className="p-2">{item.text}</a>
                  : <Link to={`/${item.link}`} className="p-2">{item.text}</Link>
                }
              </li>
            ))}
          </ul>
          {/* end footer links */}
        </div>

        {/* footer description */}
        <div className="hidden lg:block">
          <p className="serif text-xl">{footer.text}</p>
        </div>
        {/* end footer description */}

      </div>
    </FooterStyles>
  )
}

export default Footer
